import React, { useState } from "react";
import { graphql } from "gatsby";
import { Navigation } from "../components/global/nav";
import { Footer } from "../components/global/footer";
import "../styles.css"
import Breadcrumbs from "../components/global/breadcrumbs";
import SEO from "../components/global/seo";

const GuideManuMain = props => {
   const page = props.data.contentfulManufacturerLanding;
   const related_pages = props.data.allContentfulManufacturerPage.edges;
   // States
   const [selectedPage, setSelectedPage] = useState('');
   // Variables
   const slug = page.slug;
   const title = page.title;
   const title_split = title.split("-");
   const image = page.companyLogo.file.url;
   const hq = page.headquarters;
   const ceo = page.ceo;
   const founded = page.yearFounded;
   const manufacturer = page.manufacturer;
   const metadescription = page.metaDescription;

   const createPageList = () => {
      let jsx = []

      // Navigation for list of boilers
      let boilers_nav_jsx = [];

      // Navigation for list of fault codes
      let fault_nav_jsx = [];

      for (let i = 0; i < related_pages.length; i++) {
         const page = related_pages[i];
         const slug = page.node.slug;
         const category = slug.split("/")[2];
         const help_category = slug.split("/")[3]
         const clean_name = slug.split("/")[slug.split("/").length-1].replaceAll("-", " ").toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
         
         if(category === 'boilers' && slug.split("/").length > 3) {
            boilers_nav_jsx.push(
               <a href={`/${slug}`}>{clean_name}</a>
            )
         } else if (help_category === 'fault-codes' && slug.split('/').length > 4) {
            fault_nav_jsx.push(
               <a href={`/${slug}`}>{clean_name}</a>
            )
         }
      }

      jsx.push(
         <React.Fragment>
            <input
               type="radio"
               id="boilers"
               name="pages"
               className="invisible content-category-trigger"
               value={[`manufacturers/${slug.split("/")[1]}/boilers`, "Boilers"]}
            />
            <label htmlFor="boilers" className="content-category-radio">Boilers</label>

            <div className="mob-content-category-navigation-options">
               {boilers_nav_jsx}
            </div>
         </React.Fragment>
      )

      jsx.push(
         <React.Fragment>
            <input
               type="radio"
               id="fault_codes"
               name="pages"
               className="invisible content-category-trigger"
               value={[`manufacturers/${slug.split("/")[1]}/help/fault-codes`, "Fault codes"]}
            />
            <label htmlFor="fault_codes" className="content-category-radio">Fault codes</label>

            <div className="mob-content-category-navigation-options">
               {fault_nav_jsx}
            </div>
         </React.Fragment>
      )
      
      // Navigation for everything else
      for (let i = 0; i < related_pages.length; i++) {
         const el = related_pages[i];
         const slug = el.node.slug;
         const slug_split = slug.split("/");
         const category = slug_split[2];
         const help_category = slug_split[3]
         const len = slug_split.length-1;
         const final_url = slug_split[len];
         const name = final_url.replaceAll("-", " ").toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
         const navigation = JSON.parse(el.node.navigation.internal.content);
         const navigation_array = Object.entries(navigation)

         let mob_nav_jsx = [];

         for (let ind = 0; ind < navigation_array.length; ind++) {
            const elem = navigation_array[ind];
            const key_value = Object.entries(elem[1])[0]

            mob_nav_jsx.push(
               <a href={`/${slug}#${key_value[1]}`}>{key_value[0]}</a>
            )
         }

         const object_to_pass = [slug, name];

         if(category === 'boilers' || help_category === 'fault-codes') {
            // Don't add to list
         } else {
            jsx.push(
               <React.Fragment>
                  <input
                     type="radio"
                     id={final_url}
                     name="pages"
                     className="invisible content-category-trigger"
                     value={object_to_pass}
                  />
                  <label htmlFor={final_url} className={`content-category-radio`}>{name}</label>
   
                  <div className="mob-content-category-navigation-options">
                     {mob_nav_jsx}
                  </div>
               </React.Fragment>
            )
         }
         

         
      }
   
      return jsx;
   }

   const createNavList = () => {
      let jsx = [];
      const state = selectedPage.split(",");

      if (selectedPage === '') {
         jsx.push(
            <h3>Please select an option</h3>
         )
      } else {
         jsx.push(
            <h3>{state[1].split(" ")[0] === manufacturer ? '' : manufacturer} {state[1]}</h3> 
         )
      }

      let selected_object = {};

      for (let i = 0; i < related_pages.length; i++) {
         const el = related_pages[i];
         
         if (el.node.slug === state[0]) {
            selected_object = el.node;
         }
      }

      if(state[1] === "Boilers") {
         for (let i = 0; i < related_pages.length; i++) {
            const page = related_pages[i];
            const slug = page.node.slug;
            const category = slug.split("/")[2];
            const clean_name = slug.split("/")[slug.split("/").length-1].replaceAll("-", " ").toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            
            if(category === 'boilers' && slug.split("/").length > 3) {
               jsx.push(
                  <a
                     className="content-category-navigation-option"
                     href={`/${slug}`}
                  >
                     {clean_name}
                  </a>
               )
            }
         }

      } else if (state[1] === "Fault codes") {
         for (let i = 0; i < related_pages.length; i++) {
            const page = related_pages[i];
            const slug = page.node.slug;
            const category = slug.split("/")[3];
            const clean_name = slug.split("/")[slug.split("/").length-1].replaceAll("-", " ").toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            
            if(category === 'fault-codes' && slug.split("/").length > 3) {
               jsx.push(
                  <a
                     className="content-category-navigation-option"
                     href={`/${slug}`}
                  >
                     {clean_name} fault code
                  </a>
               )
            }
         }
      } else {
         if (Object.entries(selected_object).length > 0) {
            const navigation = JSON.parse(selected_object.navigation.internal.content);
            const navigation_array = Object.entries(navigation);
   
            for (let i = 0; i < navigation_array.length; i++) {
               const el = navigation_array[i];
               const key_value = Object.entries(el[1])[0]
               
               jsx.push(
                  <a
                     className="content-category-navigation-option"
                     href={`/${state[0]}#${key_value[1]}`}
                  >
                     {key_value[0]}
                  </a>
               )
            }
         }
      }

      return jsx;
   }

   const handleChange = (event) => {
      const value = event.target.value;

      setSelectedPage(value)
   }

   return (
      <React.Fragment>
         <SEO
            title={title}
            slug={slug}
            metadescription={metadescription}
            image=""
            titleTemplate="%s"
         />
         <Navigation/>
            <div className="manufacturer-title-container">
               <div className="manufacturer-title-inner-container body-inner-width">
                  <div className="manufacturer-title-text">
                     <h1>{title_split[0]}
                     <br/><span id="title_part2">{title_split[1]}</span></h1>

                     <div className="at-a-glance-container">
                        <p>Company type: <span>Manufacturer</span></p>
                        <p>HQ: <span>{hq}</span></p>
                        <p>Founded: <span>{founded}</span></p>
                        <p>CEO: <span>{ceo}</span></p>
                     </div>

                     <p>Smart Plan proudly supports covering and repairing <strong>{manufacturer} boilers</strong>. Please use this page to find what you're looking for about {manufacturer}.</p>

                     <button className="large-button green-button repair disabled">Book a repair</button>
                  </div>
                 <div className="manufacturer-title-image">
                    <img 
                     src={`https:${image}`} 
                     alt={`${manufacturer} Logo`}
                     title={`${manufacturer} are supported by Smart Plan`}
                  />
                 </div>
               </div>
            </div>

            <div className="standard-container">
               <div className="standard-inner-container body-inner-width">
                  <Breadcrumbs
                     location={slug}
                  />

                  <h2 style={{color: '#fa4590'}}>Find what you're looking for</h2>
                  <div className="content-category-finder-container">
                     <form className="content-category-finder-articles" onChange={handleChange}>
                        {createPageList()}
                     </form>
                     <div className="content-category-finder-navigation">
                        {createNavList()}
                     </div>
                  </div>
               </div>
            </div>
            
         <Footer/>
      </React.Fragment>
   )
}

export default GuideManuMain

export const pageQuery = graphql`
   query ContentfulPostBySlug( $slug: String!, $moduleRegex: String!) {
      contentfulManufacturerLanding( slug: {eq: $slug}) {
         title
         metaDescription
         updatedAt
         content {
            internal {
               content
            }
         }
         companyLogo {
            file {
               url
            }
         }
         yearFounded
         headquarters
         ceo
         slug
         manufacturer
      }

      allContentfulManufacturerPage(filter: {slug: {regex: $moduleRegex}}) {
         edges {
           node {
             title
             updatedAt
             metaDescription
             content {
               internal {
                 content
               }
             }
             articleDescription {
               articleDescription
             }
             slug
             navigation {
                internal {
                   content
                }
             }
           }
         }
       }
   }
`